import React from 'react';
import * as Ink from '@carta/ink';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';

const Tokens = () => {
  const JSON = ({ obj, level = 0, prefix = '' }) => {
    return Object.entries(obj).map(([key, value]) =>
      typeof value !== 'object' ? (
        <Ink.NewTable.Row key={key} indent={level}>
          <Ink.NewTable.Cell preset="key">
            <code>
              {prefix}
              {key}
            </code>
          </Ink.NewTable.Cell>
          <Ink.NewTable.Cell>
            {typeof value === 'string' && value.startsWith('#') && (
              <div
                style={{
                  height: '1em',
                  width: '1em',
                  border: '1px solid #333',
                  display: 'inline-block',
                  backgroundColor: value,
                }}
              />
            )}{' '}
            <code>{value}</code>
          </Ink.NewTable.Cell>
        </Ink.NewTable.Row>
      ) : (
        <>
          <Ink.NewTable.Row indent={level + 1}>
            <Ink.NewTable.Cell colSpan={2}>
              <code>
                {prefix}
                {key}
              </code>
            </Ink.NewTable.Cell>
          </Ink.NewTable.Row>
          <JSON obj={obj[key]} prefix={`${prefix}${key}.`} level={level + 1} />
        </>
      ),
    );
  };

  return (
    <Layout pageTitle="Design Tokens">
      <Ink.Box bottom="16">
        <Header title="Design Tokens" subtitle="Tokens are design decisions stored inside variables" />
      </Ink.Box>
      <Ink.NewTable height="100dvh">
        <Ink.NewTable.Head>
          <Ink.NewTable.HeadCell>Token</Ink.NewTable.HeadCell>
          <Ink.NewTable.HeadCell>Value</Ink.NewTable.HeadCell>
        </Ink.NewTable.Head>
        <Ink.NewTable.Body>
          <JSON obj={Ink.tokens} />
        </Ink.NewTable.Body>
      </Ink.NewTable>
    </Layout>
  );
};

export default Tokens;
